import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SEO from '../components/seo';
import PrivacyIndustryHero from '../components/sections/privacy/privacy-industry-hero';
import AccordionPrivacyFAQ from '../components/sections/privacy/accordion-privacy-faq';
import IndustryPrivacySelector from '../components/sections/privacy/industry-privacy-selector';
import './privacy-industry.css';

const PrivacyNightclubsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      privacyHero: file(relativePath: { eq: "hero-images/privacy-hero.png" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      privacyFeature: file(relativePath: { eq: "hero-images/privacy_feature.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const accordionQuestions = [
    {
      title: 'What information is collected and for what purpose?',
      response: `
      <p>Patronscan's online identity verification reads IDs to verify the ID's authenticity, validity and that the individual is of legal age in their respective jurisdiction. Patronscan does not retain or store any information associated with the ID.</p>
            `,
    },
    {
      title: 'Does Patronscan adhere to privacy laws in my jurisdiction?',
      response: `
      <p>Patronscan makes every effort to ensure privacy compliance in all jurisdictions in which we operate. In all consultations and investigations, the Patronscan system has been found to comply with all privacy laws in their respective jurisdictions.
            <br></br>
            For our complete Patronscan privacy policy or to request a copy of your information, please visit: <a class="text-blue" href="http://patronscan.com/privacy">https://patronscan.com/privacy</a>.</p>
            `,
    },
    {
      title: 'Does Patronscan share or sell data with third parties?',
      response: `
      No, Patronscan does not collect, share or sell any data associated with your ID.</p>
      </p>`,
    },
  ];

  return (
    <div>
      <SEO title="Our Commitment to Privacy - Patronscan" />
      <PrivacyIndustryHero
        heroImage={data.privacyHero.childImageSharp.fluid}
        heading="Privacy Policy"
        subHeading="Online Age Verification"
      />
      <h2 className="text-3xl font-hairline font-heading text-grey-dark mb-3 privacy-heading">
        {' '}
        Your Privacy
      </h2>
      <p className="privacy-text">
        Patronscan is committed to protecting people and their privacy. We comply with all privacy regulations in their
        respective jurisdictions. To learn more about Patronscan's privacy committment, click on the
        questions below.
      </p>
      <div className="privacy-accordion">
        <h2 className="text-3xl font-hairline font-heading text-grey-dark mb-3 privacy-accordion-header">
          Frequently Asked Questions
        </h2>
        <AccordionPrivacyFAQ questions={accordionQuestions} />
      </div>
      <p className="contact-style">
        For any questions that are not answered above, please email us at:{' '}
        <a href="mailto:privacy@patronscan.com" className="text-blue">
          privacy@patronscan.com
        </a>
      </p>
      <IndustryPrivacySelector />
    </div>
  );
};

export default PrivacyNightclubsPage;
